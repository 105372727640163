.Community{
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
    margin-top: 3rem;

}
.left-c{
    color: white;
    font-size: 3rem;
    position: relative;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 4rem;
}
.left-c>hr{
    width: 15.5rem;
   
   
    border: 1.5px solid var(--orange);
 /* border-radius: 20%; */
    position: absolute;
    top: -1.5rem;
}
.right-c{
    display: flex;
    gap: 3rem;
    align-items: flex-end;
}
.email-container{
    display: flex;
   
    /* background-color: gray; */
    margin: 2rem;
    gap: 1rem;

}
.email-container>input{
    background: transparent;
    border-radius: 12px;
    color: white;
    width: 15rem;
    
    border: 2px solid white;
}
.email-container>input:focus{
    outline: none;
}

  .email-container>button{
    
    background:var(--orange);
    color: white;
    width: 5rem;
  }
  @media screen and (max-width:768px){
    .Community{
        flex-direction: column;
        gap: 1rem;
    }
    .left-c{
        font-size: x-large;
        font-weight: bolder;
        flex-direction: column;
    }
    .right-c{

        padding: 2rem;
      
        justify-content: center;
       
    }
    .right-c>form>input{
        width: 10rem;
    }

}
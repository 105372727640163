.reasons{
    display: flex;
    padding: 0 2rem;
    gap: 2rem;
}
.left-r{
    flex: 1 1;
    display: grid;
    grid-template-columns:repeat(3,1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
}
.left-r>img{
    object-fit: cover;
}
.left-r>img:nth-of-type(1){
    width: 12rem;
    grid-row: 1/3;
    height: 28rem;
}
.left-r>img:nth-of-type(2){
    width: auto;
   
    height: 16rem;
    grid-column: 2/4;
}
.left-r>img:nth-of-type(3){
    width: 14rem;
   
   
}
.left-r>img:nth-of-type(4){
    width: 10rem;
   
    
}
.right-r{
    text-transform: uppercase;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    flex: 1 1;
}
.right-r>span{
 font-size: 1rem;
 font-weight: bold;
 color: var(--orange);
}
.right-r>div>span{
    font-weight: bold;
    font-size: 3rem;

}
.right-r>div>span:nth-of-type(2){
    color: white;
}
.details-r{
    color: white;
    font-weight: bold;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.details-r>div{
    display: flex;
    flex-direction: row;
    gap: 1rem;

}
.details-r>div>span>img{
    width: 2rem;
    height: 2rem;
}
.partners{
    color: gray;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.partner-pics{
    display: flex;
    flex-direction: row;
    gap:   1rem;

}
.partner-pics>img{
    width: 2.5rem;
   
}
@media screen and (max-width:768px) {
    .reasons{
        flex-direction: column;
    }
    .left-r{
        grid-auto-rows: auto;
        overflow: hidden;
    }
    .left-r>img:nth-of-type(1){
        width: 7rem;
        height: 17rem;

    }
    .left-r>img:nth-of-type(2){
        width: 15rem;
        height: 10rem;
    }
    .left-r>img:nth-of-type(3){
        width: 5rem;
        height: 6rem;
    }
    .left-r>img:nth-of-type(4){
        width: 2rem;
        height: 6rem;
    }
}
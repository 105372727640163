.plan-container{
    display: flex;
    flex-direction: column;
    gap: 3rem;
    position: relative;
}
.plan-headers{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 3rem;
   
}
.plan-headers>span{
    font-size: 3rem;
    font-weight: bold;
    font-style: italic;
    
}
.plan-headers>span:nth-of-type(2){
    color: white;
}
.plans{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}
.plan{
    display: flex;
    flex-direction: column;
    background-color: var(--caloryCard);
    color: white;
    gap: 2rem ;
    padding: 1.5rem;
    width: 15rem;
}
.plan>svg{
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}
.plan:hover{
    transform: scale(1.1);
    background: var(--planCard);
    transition: 0.5s;
    cursor: pointer;
    
}
.plan:hover>svg{
    fill: white;
}
.plan:hover>.btn{
    color: var(--orange);
}
.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.plan>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}
.plan>:nth-child(5){
    font-size: 0.8rem;
    
}
.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
} 
.feature>img{
    width: 1rem;
}
@media screen and (max-width:768px){
    .plan-headers{
        flex-direction: column;
        font-size: 1rem;
        gap: 1rem;
    }
    .plans{
        flex-direction: column;
        transform: scale(0.9);
    }

}
.header{
    display: flex;
    justify-content: space-between;
    
}
.logo{
    display: flex;
    flex-direction: row;
}
.header-menu{
    display:flex;
    list-style: none;
    gap: 2rem;
    color: white;

}
.header-menu>li:hover{
 cursor: pointer;
 color:  #f48915;
}

.logo-text{
    margin-top: 10px;
    font-size: 3rem;
    color:#f48915;
    font-weight: bold;
}
@media screen and (max-width:768px){
    .header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;

    }
    .header-menu{
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
    }
    
}


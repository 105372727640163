.hero{
    display: flex;
    flex-direction: row;
   justify-content: space-between;
}
.h-left{
    padding: 2rem;
    padding-top: 1rem;
    flex: 3;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}
.h-right{
    flex:1;
}
.best-ad{
    margin-top: 4rem;
    width: fit-content;
    padding: 20px 13px;
    border-radius: 4rem;
    background-color: #363d42;
    color: white;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;


}
.best-ad>span{
    z-index: 2;
}
.best-ad>div{

 position: absolute;
 background-color:  #f48915;
 height: 80%;
 width:4.5rem ;
 border-radius: 3rem;
 left: 8px;
 top: 0;
margin-top: 6px;

z-index: 1;


}
.hero-text{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    font-size: 4.5rem;
    font-weight: bold;
    text-overflow: inherit;
    color: white;
}
.hero-text>div:nth-of-type(3){
    font-size: 1rem;
    font-weight: 200;
    letter-spacing: 1px;
    width: 80%;
}
.figures{
    display: flex;
    gap: 3rem;

}
.fig-content{
    display: flex;
    flex-direction: column;
   
}
.figures>div>span:nth-of-type(1){
    color: white;
    font-size: 2rem;
}
.figures>div>span:nth-of-type(2){
    color:var(--gray);
    font-size: 1rem;
    text-transform: uppercase;
}
.hero-btn{
    display: flex;
    gap: 2rem;
}
.hero-btn>button:nth-of-type(1){
    background-color :#f48915; ;
    color: white;
    width: 8rem;
    text-align: center;
}
.hero-btn>button:nth-of-type(2){
    border:2px solid  #f48915; ;
    background-color: transparent;
    color: white;
   width: 8rem;
    text-align: center;
}
.h-right{
    background-color:  #f48915;
    position: relative;
}
.h-right>button{
   position:absolute;
    right: 5rem;
    top: 2rem;
    color: black;
}
.heart-rate{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color:#464D53; 
    width: fit-content;
    padding: 1rem;
    align-items: flex-start;
    border-radius: 5px;
    position: absolute;
    top: 7rem;
    right: 4rem;
}
.heart-rate>img{
    height: 30px;
   
}
.heart-rate>span:nth-of-type(1){
    color:var(--gray);

}
.heart-rate>span:nth-of-type(2){
    color: white;
    font-size: 1.5rem;
}
.hero-img{
    position: absolute;
    top: 10rem;
    right:8rem;
    width: 23rem;
}
.hero-back{
    position: absolute;
    top: 4rem;
    right: 20rem;
    width: 15rem;
    z-index: -1;
}
.calories{
    display: flex;
    gap: 2rem;
    background-color: var(--caloryCard);
    border-radius: 5px;
    padding: 1rem;
    width: fit-content;
    top: 32rem;
    right: 28rem;
    position: absolute;
}
.calories>img{
    width: 3rem;
}
.calories>div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.calories>div>span:nth-of-type(1){
    color: var(--gray);

}
.calories>div>span:nth-of-type(2){
color: white;
font-size: 1.5rem;
}
@media screen and (max-width:768px) {

    .hero{
        flex-direction: column;
    }
    .best-ad{
        margin-top: 0;
        font-size: small;
        align-self: center;
        transform: scale(0.8);
    }
    .hero-text{
        font-size: xx-large;
        align-items: center;
        justify-content: center;
    }
    .hero-text>div:nth-of-type(3){
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;
    }
    .hero-btn{
        justify-content: center;
    }
    .figures>div>span>:nth-of-type(1){
        font-size: large;
    }
    .figures>div>span>:nth-of-type(2){
        font-size: small;
    }
    .h-right{
        position: relative;
        background: none;

    }
    .heart-rate{
        left: 1rem;
        top: 2rem;
    }
    .calories{
        position: relative;
        top: 5rem;
        left: 2rem;
    }
    .calories>img{
        width: 2rem;

    }
    .calories>div>:nth-of-type(2){
        color: white;
        font-size: 1rem;

    }
    .hero-img{
        position: relative;
         width: 15rem;
         left: 7rem;
         top: 4rem;
         align-self: center;

    }
    .hero-back{
        width: 15rem;
        left: 2rem;
        top: 0rem;

    }
    }
 
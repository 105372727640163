.program-headers{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 4rem;
}
.program-headers>span{
    font-size: 3rem;
    font-weight: bold;
    font-style: italic;
    
}
.program-headers>span:nth-of-type(2){
    color: white;
}
.program-boxes{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 30px;
    
}
.box{
    width: 290px;
    height: 280px;
    border: 2px solid transparent;
    background-color: var(--gray);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.box>img{
    width: 30px;
    height: 30px;
}

.program-types{
    margin: 2rem;
    display: flex;
    gap: 1rem;
   
    
}
.category{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    background-color: gray;
    padding: 2rem;
    gap: 1rem;
}
.category>:nth-child(1){
 width: 2rem;
 height: 2rem;
 fill: white;
}
.category>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.category>:nth-child(3){
    font-size: 0.9rem;
    line-height: 24px;
}
.join-button{
    display: flex;
    align-items: center;
    gap: 2rem;
}
.join-button>span>img{
    width: 1rem;
}
.category:hover{
    background: var(--planCard);
    cursor: pointer;
    transition: 0.5s;
    transform: scale(1.1);
}
@media screen and (max-width:768px) {
    .program-headers{
    flex-direction: column;
    gap: 1rem;
    font-size: 2rem;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;

    }
    .program-types{
        flex-direction: column;
    }
    
}